.rightIcon {
  margin: auto;
}

.title {
  font-weight: bolder;
}

.subtitle {
  font-size: smaller;
}

.activeSubtitle {
  font-size: smaller;
  color: #00d963;
  font-weight: bolder;
  padding-top: 25px;
}

.roundButton {
  border-radius: 50%;
  padding: 10px;
  border: none;
  background-color: #00d963;
}

.roundButton i {
  color: white;
}

.spinner {
  height: 100%;
  display: flex;
}
