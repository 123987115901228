.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #eee;
  -webkit-box-shadow: 0px 3px 30px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 30px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 30px -9px rgba(0, 0, 0, 0.75);
}

.buttonDiv {
  width: 100px;
  text-align: center;
  float: left;
  background-color: #0071bf;
  transition: 0.4s ease-in;
  cursor: pointer;
}

.buttonDiv:hover {
  background-color: #3cb2df;
}

.goBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 70px;
  font-size: 20px;
  color: white;
  transition: 0.2s ease-in;
  text-align: center;
  cursor: pointer;
}

.rowTitle {
  float: left;
  width: auto;
  height: 100%;
  padding-left: 30px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.profilePic {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.img {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  border-radius: 50%;
  border: 1px solid blanchedalmond;
}

.profileMetadata {
  display: flex;
  flex-direction: column;
}

.menu {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.icon {
  background-color: rgb(236, 236, 236);
  border: 1px solid rgb(211, 211, 211);
  color: #0071bf;
  padding: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s ease-in;
  margin-right: 25px;
  margin-left: 25px;
}

.icon:active {
  background-color: rgb(226, 226, 226);
}

.icon:hover {
  background-color: rgb(226, 226, 226);
  color: #3cb2df;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
}

.item1 {
  width: 40%;
  height: 325px;
  background-color: #bec8cf;
  border-radius: 10px;
}

.item2 {
  width: 40%;
  background-color: #8d959b;
  border-radius: 10px;
}
