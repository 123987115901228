.container {
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
  background-color: #e2e2e2;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100vw;
  }
}

.default {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.default i {
  color: #aaa;
  font-size: 10em;
}

.default h2 {
  color: #404040;
}

.default strong {
  color: #404040;
}
