.container {
  display: flex;
  flex-direction: column;
  border: 1px solid #7070703a;
  border-radius: 15px;
}

.header {
  width: 550px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 25px;
  align-items: center;
  border-bottom: 1px solid #7070703a;
}

.headerTitle p {
  margin: 10px;
  font-size: 22px;
}

.headerButton {
  display: flex;
  align-items: center;
  margin: 15px;
}

.list {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #7070702a;
  padding: 5px 25px;
}

.optionsList {
  display: flex;
  min-width: 120px;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  align-self: center;
}

@media (max-width: 600px) {
  .header {
    width: 300px;
    flex-direction: column;
    justify-items: center;
    text-align: center;
  }
  .list {
    flex-direction: column;
  }

  .optionsList {
    display: flex;
    flex-direction: row;
    width: 50%;
  }
}
