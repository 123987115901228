.container {
  grid-area: tabs;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #eeeeee;
  padding: 0.5em;
}

.nav {
  display: block;
  background-color: inherit;
  float: left;
  width: 100%;
}

.tab {
  background-color: inherit;
  padding: 14px 16px;
  outline: none;
  border: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 800;
}

.tab:hover {
  background-color: #ddd;
  cursor: pointer;
}

.tabActive {
  transition: 0.3s;
  background-color: #ccc;
}

.tabcontent {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid #ccc;
}
