.container {
  grid-area: goals;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  background-color: #eeeeee;
  overflow: hidden;
}

.content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 4px;
}

.content::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.content::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 4px;
}

.header {
  padding: 0.5em;
}

.header button {
  float: right;
  padding: 10px 16px;
  background-color: #eb5c74;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.header button > i {
  margin: 0 6px 0 0;
  text-transform: uppercase;
}

.content {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 0.2em;
  padding: 0.2em;
  border: 1px solid rgb(204, 204, 204);
  border-top: none;
  overflow-y: auto;
}

.goal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #ddd;
  word-wrap: break-word;
}

.goal:hover {
  background-color: #ccc;
}

.left,
.right {
  display: flex;
  flex-direction: column;
}

.left {
  max-width: 80%;
}

.left strong {
  font-family: 'Open Sans', sans-serif;
}

.right {
  align-items: center;
  justify-content: center;
}

.right strong {
  font-family: 'Open Sans', sans-serif;
}

.modal {
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  -webkit-box-shadow: -2px 2px 38px 0px rgba(128, 128, 128, 1);
  -moz-box-shadow: -2px 2px 38px 0px rgba(128, 128, 128, 1);
  box-shadow: -2px 2px 38px 0px rgba(128, 128, 128, 1);
}

@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .goal {
    height: auto;
    width: 100%;
  }

  .content {
    grid-template-columns: unset;
    grid-auto-rows: unset;
    grid-gap: unset;
    width: 100%;
  }

  .header {
    padding: 0.5em;
    background-color: #ccc;
  }

  .left {
    text-align: justify;
    width: 80%;
  }

  .header button {
    float: unset;
    width: 100%;
  }

  .right {
    width: 20%;
  }
}
