.container {
  width: 100%;
}

.container h4 {
  margin: 0;
}

.card {
  position: relative;
  background-color: white;
  padding: 1em;
  font-family: Montserrat, sans-serif;
  font-style: italic;
  margin: 10px 0 20px 0;
  border-radius: 3px;
  line-height: 140%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 0;
}

.card:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25), 0 3px 4px rgba(0, 0, 0, 0.22);
}

.edit {
  display: none;
  padding: 10px 10px;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
  overflow: hidden;
  border: none;
  outline: none;
  border-radius: 5px;
}

.edit:hover {
  background-color: #ddd;
  border-radius: 5px;
}

.edit i {
  color: darkgray;
  font-size: 16px;
  padding: 0px;
}

.left {
  width: 90%;
}

.right {
  width: 10%;
}

.card:hover .edit {
  display: block;
}

.card:focus .edit {
  display: none;
}

@media (max-width: 600px) {
  .card {
    padding: 15px;
  }

  .edit {
    display: unset;
  }

  .left {
    width: 85%;
  }
  .right {
    background-color: red;
  }
}
