.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 200px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card h2 {
  margin: 10px 0;
}

.form {
  display: flex;
  flex-direction: column;
}

.field {
  padding: 8px;
  margin: 15px 0 0 0;
  width: 300px;
  font-family: Montserrat;
  border: 1px solid #ddd;
}

.button {
  padding: 10px 16px;
  background-color: #eb5c74;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  outline: none;
  margin: 10px 0 0 0;
}

.error {
  font-family: 'Open Sans', sans-serif;
  color: #cc0000;
}

.logo {
  width: 110px;
  height: 107.3px;
  object-fit: cover;
  margin: -50px 0 40px 0;
}
