.container {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.input {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  padding: 5px;
  margin: 0 0 10px 0;
  border: 1px solid #ccc;
}

.button {
  padding: 10px 16px;
  background-color: #41bfe7;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;
  outline: none;
  margin: 10px 0 0 0;
  cursor: pointer;
}

.row {
  display: flex;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .container {
    width: auto;
  }

  .modal {
    background-color: violet;
  }
}
