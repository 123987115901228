.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  background-color: #e2e2e2;
}

.default {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.default i {
  color: #aaa;
  font-size: 10em;
}

.default h2 {
  color: #404040;
}

.default strong {
  color: #404040;
}

.message {
  display: block;
}

.topSpinner {
  margin: 10px auto;
}

@media only screen and (max-width: 600px) {
  .default {
    text-align: center;
  }
}
