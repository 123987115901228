.icon {
  border-radius: 100%;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.icon:hover {
  color: white;
}

.icon:visited {
  background-color: blue;
}

.tooltip {
  z-index: 99;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgba(85, 85, 85, 0.842);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  bottom: 100%;
  width: fit-content;
  left: 50%;
  margin-left: -70px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 16px;
  min-width: 200px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
