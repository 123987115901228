@import url('https://fonts.googleapis.com/css?family=Montserrat|Open+Sans');

html,
body,
#root {
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Montserrat', 'Open Sans', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #cde6fa 14%, #edf7ff 64%);
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover {
  background: radial-gradient(circle, #b5d7f2 14%, #b1d6f5 64%);
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 7px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}
