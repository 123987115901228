.container {
  grid-area: header;
  display: flex;
  flex-direction: row;
  grid-column: 1 / -1;
  padding: 1em;
  /* padding-bottom: 50em; */
}

.left {
  flex-direction: column;
  flex: 1;
}

.right {
  flex: 1;
  margin: auto;
}

.verticalLine {
  border-left: thin solid #ccc;
  align-self: center;
  height: 95%;
}

@media (max-width: 600px) {
  .container {
    display: unset;
    grid-area: unset;
  }

  .verticalLine {
    border-left: thin solid #ccc;
    align-self: center;
    height: 0%;
  }

  .left {
    padding-bottom: 15px;
  }

  .right {
    padding-top: 5px;
    border-top: 1px solid #7070703a;
  }
}
