.container {
  display: flex;
  flex-direction: column;
  height: 35vh;
}

.nodata {
  margin: 0 auto;
  font-family: Montserrat, sans-serif;
  font-style: italic;
}

.tooltip {
  font-family: Montserrat, sans-serif;
  color: white;
  padding: 14px 20px;
  background-color: rgb(0, 0, 0, 0.9);
  border-radius: 3px;
}

.tooltip p {
  margin: 10px 0;
}

@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 50vh;
    text-align: center;
  }

  .tooltip {
    padding: 7px;
    border-radius: 3px;
    text-align: left;
  }

  .tooltip p {
    margin: 5px;
  }
}
