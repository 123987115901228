.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 350px;
  width: 330px;
  text-align: center;
  margin-top: 20px;
}

.star {
  font-size: 32px;
  padding: 0 5px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.star:active {
  font-size: 36px;
}

.input {
  width: 100%;
  margin: 25px 0;
  border: 1px solid #7070707a;
  border-radius: 5px;
  padding: 10px;
}

.success {
  font-size: 22px;
}
