.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 30px;
  min-width: 330px;
  max-width: 330px;
  min-height: 350px;
}

.error {
  color: red;
  text-align: center;
  font-weight: bold;
}

select {
  border-radius: 5px;
  padding: 7px;
  border: 1px solid #707070;
  width: 100%;
}

input {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #707070;
}

.container p {
  margin: 20px 0;
}

@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    padding: 30px;
    min-height: 350px;
  }
}
