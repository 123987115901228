.container {
  /* grid-row: span 2; */
  grid-area: notes;
  padding: 0 0.5em;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 80vh;
}

.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 4px;
}

.container::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.container::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 4px;
}

.form {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  height: 30vh;
  margin-bottom: 30px;
}

.button {
  align-self: flex-end;
  margin: 0.5em 0;
  background-color: #0071bf;
  border: 0;
  padding: 0.5em 1em;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  resize: none;
  font-weight: 500;
}

.notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
    padding-top: 20px;
    max-height: 70vh;
    border-bottom: 1px solid #7070703a;
  }

  .title {
    font-size: 22px;
    font-weight: bold;
  }

  .notes {
    text-align: left;
  }
}
