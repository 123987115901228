.container {
  flex: 1;
  padding: 1em;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  text-align: center;
}

.more {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: #007bff;
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
}

.stats {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.stats small {
  margin: 3px 0;
}

@media (max-width: 600px) {
  .more {
    margin-top: 35px;
  }
}
