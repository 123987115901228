.container {
  grid-area: appos;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  background-color: #eeeeee;
  overflow: overlay;
}

.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 4px;
}

.container::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.container::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 4px;
}

.header {
  padding: 0.5em;
}

.header button {
  float: right;
  padding: 10px 16px;
  background-color: #eb5c74;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;
  outline: none;
}

.header button > i {
  margin: 0 6px 0 0;
  text-transform: uppercase;
}

.header2 {
  padding: 0.5em;
}

.header2 button {
  float: right;
  padding: 10px 16px;
  background-color: #3cb2df;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;
  outline: none;
}

.header2 button > i {
  margin: 0 6px 0 0;
  text-transform: uppercase;
}

.content {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 0.2em;
  padding: 0.2em;
  border: 1px solid rgb(204, 204, 204);
  border-top: none;
  overflow-y: auto;
}

.goal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #ddd;
}

.goal:hover {
  background-color: #ccc;
}

.goalSuccessful {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #00d963;
}

.goalCanceled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #eb5c74;
}

/* .goalCanceled:hover {
  background-color: #ccc;
} */

/* .left,
.right {
  display: flex;
  flex-direction: column;
} */

.left {
  display: flex;
  flex-direction: column;
}

.right {
  display: flex;
  flex-direction: row;
}

.left {
  max-width: 80%;
}

.left strong {
  font-family: 'Open Sans', sans-serif;
}

.right {
  align-items: center;
  justify-content: center;
}

.right strong {
  font-family: 'Open Sans', sans-serif;
}

.appoCard {
  background-color: #ddd;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.appoDate {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.appoStatus {
  font-size: 20px;
  width: fit-content;
  border-radius: 5px;
  font-weight: bold;
  align-self: right;
}

.appoOptions {
  display: flex;
  flex-direction: column;
}

@media (min-width: 600px) {
  .appoCard {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}
