@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
.Video_container__Pr2UB {
  display: flex;
  width: 100%;
  height: 94vh;
  background-color: #333;
  position: relative;
}

.Video_remoteMedia__2NWIe {
  width: 100%;
  position: relative;
  align-items: center;
  text-align: center;
  z-index: 0;
}

.Video_video__20OqZ {
  height: 100vh;
  margin-left: 50%;
  transform: translateX(-50%);
}

.Video_localVideo__TwsZs {
  width: 100%;
}

.Video_localMedia__1YaQD {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 130px;
  /* height: 200px; */
  z-index: 1;
  background-color: #333;
}

.Video_disconnect__1n9z8 {
  background-color: red;
  text-align: center;
  padding: 0 15px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  transition: 0.3s ease-in;
}

.Video_disconnect__1n9z8:hover {
  transform: rotate(25deg);
}

.Video_errorDiv__25wXz {
  background-color: rgb(192, 3, 3);
  color: white;
  width: -webkit-max-content;
  width: max-content;
  padding: 15px;
  border-radius: 15px;
  z-index: 1;
}

.Video_connection__1JdmR {
  font-size: 55px;
  text-align: center;
  margin: 0.5em;
  z-index: 3;
  display: flex;
  width: 250px;
  justify-content: space-between;
}

.Video_errorDiv__25wXz {
  position: absolute;
  left: 0;
  right: 0;
  top: 20;
  margin: 20px auto;
  text-align: center;
  background-color: rgb(192, 3, 3);
  color: white;
  width: -webkit-max-content;
  width: max-content;
  padding: 15px;
  border-radius: 15px;
  z-index: 1;
}

.Video_buttons__1Ua7k {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: -webkit-max-content;
  width: max-content;
  justify-content: center;
  align-items: center;
}

.Video_toastDiv__8LYSY {
  background-color: rgba(85, 85, 85, 0.842);
  color: white;
  border-radius: 30px;
  width: -webkit-max-content;
  width: max-content;
  padding: 15px;
  text-align: center;
}

.Video_icon__3kn9P {
  z-index: 2;
  border-radius: 100%;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.Video_icon__3kn9P:hover {
  color: white;
}

.Video_icon__3kn9P:visited {
  background-color: blue;
}

@media (min-width: 600px) {
  .Video_container__Pr2UB {
    margin: auto;
  }

  .Video_video__20OqZ {
    height: 100vh;
    margin-left: unset;
    transform: unset;
  }

  .Video_localMedia__1YaQD {
    bottom: 10px;
    right: 20px;
    width: 300px;
    height: auto;
    z-index: 1;
  }
}

.Modal_modalButtons__1zePX {
  display: flex;
}

.Modal_buttons__3IY1F {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.Modal_modal__1Q2IY {
  z-index: 999;
  background-color: white;
  padding: 20px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  border: 1px solid #707070;
  border-radius: 5px;
}

.Modal_close__iJGrr {
  width: 100%;
  text-align: right;
  top: 10px;
  right: 10px;
  position: absolute;
}

.Modal_close__iJGrr i {
  color: rgb(97, 97, 97);
  padding: 10px 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.1s ease-in;
}

.Modal_close__iJGrr i:hover {
  color: rgb(70, 69, 69);
  background-color: rgb(231, 231, 231);
  padding: 12px 14px;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .Modal_modal__1Q2IY {
    max-height: 600px;
    overflow-y: unset;
  }
}

.Tooltip_icon__1c1E- {
  border-radius: 100%;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.Tooltip_icon__1c1E-:hover {
  color: white;
}

.Tooltip_icon__1c1E-:visited {
  background-color: blue;
}

.Tooltip_tooltip__23jSo {
  z-index: 99;
}

.Tooltip_tooltip__23jSo .Tooltip_tooltiptext__3VkRU {
  visibility: hidden;
  background-color: rgba(85, 85, 85, 0.842);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  bottom: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  left: 50%;
  margin-left: -70px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 16px;
  min-width: 200px;
}

.Tooltip_tooltip__23jSo .Tooltip_tooltiptext__3VkRU::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
}

.Tooltip_tooltip__23jSo:hover .Tooltip_tooltiptext__3VkRU {
  visibility: visible;
  opacity: 1;
}

.HeaderLeft_container__2zwGD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.HeaderLeft_avatar__2clEw {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.HeaderLeft_profile__iSvKe {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-transform: capitalize;
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;
}

.HeaderLeft_profile__iSvKe strong {
  font-size: 1.5em;
  font-family: 'Open Sans', sans-serif;
}

.HeaderLeft_row__2KZ2K {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
}

.HeaderLeft_iconDiv__2lMV_ {
  width: 25px;
  text-align: center;
}

.HeaderLeft_icon__3OUft {
  margin-right: 10px;
  color: #404040;
  align-self: center;
}

.HeaderLeft_bold__1AL_B {
  font-weight: bold;
  text-align: center;
}

@media (max-width: 600px) {
  .HeaderLeft_container__2zwGD {
    display: unset;
  }

  .HeaderLeft_avatar__2clEw {
    padding: 10px;
  }

  .HeaderLeft_profile__iSvKe {
    padding: 0px 20px;
  }

  .HeaderLeft_profile__iSvKe strong {
    font-size: 20px;
  }
}

.HeaderRight_container__2sKra {
  flex: 1 1;
  padding: 1em;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  text-align: center;
}

.HeaderRight_more__ZeuIx {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: #007bff;
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
}

.HeaderRight_stats__oFlrZ {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.HeaderRight_stats__oFlrZ small {
  margin: 3px 0;
}

@media (max-width: 600px) {
  .HeaderRight_more__ZeuIx {
    margin-top: 35px;
  }
}

.PatientHeader_container__3_p2M {
  grid-area: header;
  display: flex;
  flex-direction: row;
  grid-column: 1 / -1;
  padding: 1em;
  /* padding-bottom: 50em; */
}

.PatientHeader_left__3VyNP {
  flex-direction: column;
  flex: 1 1;
}

.PatientHeader_right__j_hTb {
  flex: 1 1;
  margin: auto;
}

.PatientHeader_verticalLine__3c3WK {
  border-left: thin solid #ccc;
  align-self: center;
  height: 95%;
}

@media (max-width: 600px) {
  .PatientHeader_container__3_p2M {
    display: unset;
    grid-area: unset;
  }

  .PatientHeader_verticalLine__3c3WK {
    border-left: thin solid #ccc;
    align-self: center;
    height: 0%;
  }

  .PatientHeader_left__3VyNP {
    padding-bottom: 15px;
  }

  .PatientHeader_right__j_hTb {
    padding-top: 5px;
    border-top: 1px solid #7070703a;
  }
}

.HumorMonitoring_container__3oB0i {
  display: flex;
  flex-direction: column;
  height: 35vh;
}

.HumorMonitoring_nodata__Trbgz {
  margin: 0 auto;
  font-family: Montserrat, sans-serif;
  font-style: italic;
}

.HumorMonitoring_tooltip__27euZ {
  font-family: Montserrat, sans-serif;
  color: white;
  padding: 14px 20px;
  background-color: rgb(0, 0, 0, 0.9);
  border-radius: 3px;
}

.HumorMonitoring_tooltip__27euZ p {
  margin: 10px 0;
}

@media (max-width: 600px) {
  .HumorMonitoring_container__3oB0i {
    display: flex;
    flex-direction: column;
    height: 50vh;
    text-align: center;
  }

  .HumorMonitoring_tooltip__27euZ {
    padding: 7px;
    border-radius: 3px;
    text-align: left;
  }

  .HumorMonitoring_tooltip__27euZ p {
    margin: 5px;
  }
}

.Appointments_container__V-RH4 {
  grid-area: appos;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  background-color: #eeeeee;
  overflow: overlay;
}

.Appointments_container__V-RH4::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 4px;
}

.Appointments_container__V-RH4::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.Appointments_container__V-RH4::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 4px;
}

.Appointments_header__pVDan {
  padding: 0.5em;
}

.Appointments_header__pVDan button {
  float: right;
  padding: 10px 16px;
  background-color: #eb5c74;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;
  outline: none;
}

.Appointments_header__pVDan button > i {
  margin: 0 6px 0 0;
  text-transform: uppercase;
}

.Appointments_header2__cWeIE {
  padding: 0.5em;
}

.Appointments_header2__cWeIE button {
  float: right;
  padding: 10px 16px;
  background-color: #3cb2df;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;
  outline: none;
}

.Appointments_header2__cWeIE button > i {
  margin: 0 6px 0 0;
  text-transform: uppercase;
}

.Appointments_content__3O-Tn {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-gap: 0.2em;
  padding: 0.2em;
  border: 1px solid rgb(204, 204, 204);
  border-top: none;
  overflow-y: auto;
}

.Appointments_goal__aJEuM {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  padding: 1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #ddd;
}

.Appointments_goal__aJEuM:hover {
  background-color: #ccc;
}

.Appointments_goalSuccessful__3Q63T {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  padding: 1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #00d963;
}

.Appointments_goalCanceled__2lhnp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  padding: 1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #eb5c74;
}

/* .goalCanceled:hover {
  background-color: #ccc;
} */

/* .left,
.right {
  display: flex;
  flex-direction: column;
} */

.Appointments_left__o_8jd {
  display: flex;
  flex-direction: column;
}

.Appointments_right__1YUjs {
  display: flex;
  flex-direction: row;
}

.Appointments_left__o_8jd {
  max-width: 80%;
}

.Appointments_left__o_8jd strong {
  font-family: 'Open Sans', sans-serif;
}

.Appointments_right__1YUjs {
  align-items: center;
  justify-content: center;
}

.Appointments_right__1YUjs strong {
  font-family: 'Open Sans', sans-serif;
}

.Appointments_appoCard__HUWz- {
  background-color: #ddd;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.Appointments_appoDate__104SD {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.Appointments_appoStatus__ShDjY {
  font-size: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  font-weight: bold;
  align-self: right;
}

.Appointments_appoOptions__25y0O {
  display: flex;
  flex-direction: column;
}

@media (min-width: 600px) {
  .Appointments_appoCard__HUWz- {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

.Reschedule_reschedule__107uh {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.Reschedule_calendar__eZx3O {
  display: flex;
  justify-content: 'column';
  align-self: 'center';
  margin: 20px auto;
  background-color: blue;
  border-radius: 10px;
}

.Reschedule_agenda__3W-JN {
  max-width: 640px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.Reschedule_hour__YXIbn {
  background-color: #2473a8;
  color: white;
  padding: 3px 10px;
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.Reschedule_hour__YXIbn:hover {
  background-color: #5ab9f8;
}

.Reschedule_error__3ArC0 {
  color: red;
  font-weight: bold;
  margin-bottom: 15px;
}

.PatientGoals_container__z7uJx {
  grid-area: goals;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  background-color: #eeeeee;
  overflow: hidden;
}

.PatientGoals_content__EB5Cx::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 4px;
}

.PatientGoals_content__EB5Cx::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.PatientGoals_content__EB5Cx::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 4px;
}

.PatientGoals_header__1HBU3 {
  padding: 0.5em;
}

.PatientGoals_header__1HBU3 button {
  float: right;
  padding: 10px 16px;
  background-color: #eb5c74;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.PatientGoals_header__1HBU3 button > i {
  margin: 0 6px 0 0;
  text-transform: uppercase;
}

.PatientGoals_content__EB5Cx {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-gap: 0.2em;
  padding: 0.2em;
  border: 1px solid rgb(204, 204, 204);
  border-top: none;
  overflow-y: auto;
}

.PatientGoals_goal__ZKpYS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  padding: 1em;
  transition: background-color 0.2s ease-in-out;
  background-color: #ddd;
  word-wrap: break-word;
}

.PatientGoals_goal__ZKpYS:hover {
  background-color: #ccc;
}

.PatientGoals_left__3eyj8,
.PatientGoals_right__1d3yb {
  display: flex;
  flex-direction: column;
}

.PatientGoals_left__3eyj8 {
  max-width: 80%;
}

.PatientGoals_left__3eyj8 strong {
  font-family: 'Open Sans', sans-serif;
}

.PatientGoals_right__1d3yb {
  align-items: center;
  justify-content: center;
}

.PatientGoals_right__1d3yb strong {
  font-family: 'Open Sans', sans-serif;
}

.PatientGoals_modal__1l_yT {
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: -2px 2px 38px 0px rgba(128, 128, 128, 1);
}

@media (max-width: 600px) {
  .PatientGoals_container__z7uJx {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .PatientGoals_goal__ZKpYS {
    height: auto;
    width: 100%;
  }

  .PatientGoals_content__EB5Cx {
    grid-template-columns: unset;
    grid-auto-rows: unset;
    grid-gap: unset;
    width: 100%;
  }

  .PatientGoals_header__1HBU3 {
    padding: 0.5em;
    background-color: #ccc;
  }

  .PatientGoals_left__3eyj8 {
    text-align: justify;
    width: 80%;
  }

  .PatientGoals_header__1HBU3 button {
    float: unset;
    width: 100%;
  }

  .PatientGoals_right__1d3yb {
    width: 20%;
  }
}

.GoalsCreate_container__3hIcn {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.GoalsCreate_input__2-k2r {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  padding: 5px;
  margin: 0 0 10px 0;
  border: 1px solid #ccc;
}

.GoalsCreate_button__3lWRa {
  padding: 10px 16px;
  background-color: #41bfe7;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;
  outline: none;
  margin: 10px 0 0 0;
  cursor: pointer;
}

.GoalsCreate_row__31ZqM {
  display: flex;
  justify-content: space-between;
}

.GoalsCreate_column__2S_l7 {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .GoalsCreate_container__3hIcn {
    width: auto;
  }

  .GoalsCreate_modal__2SLnV {
    background-color: violet;
  }
}

.PatientMonitoringStyle_container__mkKB7 {
  grid-area: tabs;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: #eeeeee;
  padding: 0.5em;
}

.PatientMonitoringStyle_nav__1qhfB {
  display: block;
  background-color: inherit;
  float: left;
  width: 100%;
}

.PatientMonitoringStyle_tab__2f9CR {
  background-color: inherit;
  padding: 14px 16px;
  outline: none;
  border: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 800;
}

.PatientMonitoringStyle_tab__2f9CR:hover {
  background-color: #ddd;
  cursor: pointer;
}

.PatientMonitoringStyle_tabActive__39s-V {
  transition: 0.3s;
  background-color: #ccc;
}

.PatientMonitoringStyle_tabcontent__2P2pV {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid #ccc;
}

.NotesCard_container__2smF4 {
  width: 100%;
}

.NotesCard_container__2smF4 h4 {
  margin: 0;
}

.NotesCard_card__bAZTv {
  position: relative;
  background-color: white;
  padding: 1em;
  font-family: Montserrat, sans-serif;
  font-style: italic;
  margin: 10px 0 20px 0;
  border-radius: 3px;
  line-height: 140%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 0;
}

.NotesCard_card__bAZTv:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25), 0 3px 4px rgba(0, 0, 0, 0.22);
}

.NotesCard_edit__v52Y4 {
  display: none;
  padding: 10px 10px;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
  overflow: hidden;
  border: none;
  outline: none;
  border-radius: 5px;
}

.NotesCard_edit__v52Y4:hover {
  background-color: #ddd;
  border-radius: 5px;
}

.NotesCard_edit__v52Y4 i {
  color: darkgray;
  font-size: 16px;
  padding: 0px;
}

.NotesCard_left__3xIQq {
  width: 90%;
}

.NotesCard_right__B6wH4 {
  width: 10%;
}

.NotesCard_card__bAZTv:hover .NotesCard_edit__v52Y4 {
  display: block;
}

.NotesCard_card__bAZTv:focus .NotesCard_edit__v52Y4 {
  display: none;
}

@media (max-width: 600px) {
  .NotesCard_card__bAZTv {
    padding: 15px;
  }

  .NotesCard_edit__v52Y4 {
    display: unset;
  }

  .NotesCard_left__3xIQq {
    width: 85%;
  }
  .NotesCard_right__B6wH4 {
    background-color: red;
  }
}

.PatientNotes_container__3GJDe {
  /* grid-row: span 2; */
  grid-area: notes;
  padding: 0 0.5em;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 80vh;
}

.PatientNotes_container__3GJDe::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 4px;
}

.PatientNotes_container__3GJDe::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.PatientNotes_container__3GJDe::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 4px;
}

.PatientNotes_form__2v6M9 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  height: 30vh;
  margin-bottom: 30px;
}

.PatientNotes_button__3DML- {
  align-self: flex-end;
  margin: 0.5em 0;
  background-color: #0071bf;
  border: 0;
  padding: 0.5em 1em;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.PatientNotes_textarea__3oJ_B {
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  resize: none;
  font-weight: 500;
}

.PatientNotes_notes__3FJU7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (max-width: 600px) {
  .PatientNotes_container__3GJDe {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
    padding-top: 20px;
    max-height: 70vh;
    border-bottom: 1px solid #7070703a;
  }

  .PatientNotes_title__1lK3y {
    font-size: 22px;
    font-weight: bold;
  }

  .PatientNotes_notes__3FJU7 {
    text-align: left;
  }
}

.Patient_container__1ZZmm {
  display: grid;
  grid-template-columns: 5fr 5fr;
  /* grid-template-rows: 165px auto 250px; */
  grid-template-areas:
    'header header'
    'tabs notes'
    'appos notes';
  /* 'goals notes'; */
  /* height: 100%; */
}

@media (max-width: 600px) {
  .Patient_container__1ZZmm {
    display: unset;
    min-height: unset;
    grid-template-columns: unset;
    grid-template-rows: unset;
    grid-template-areas: unset;
    grid-gap: unset;
  }
}

.Login_container__2IH6b {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 200px;
}

.Login_card__3l1oG {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.Login_card__3l1oG h2 {
  margin: 10px 0;
}

.Login_form__2l3wS {
  display: flex;
  flex-direction: column;
}

.Login_field__jMdl5 {
  padding: 8px;
  margin: 15px 0 0 0;
  width: 300px;
  font-family: Montserrat;
  border: 1px solid #ddd;
}

.Login_button__2OtO9 {
  padding: 10px 16px;
  background-color: #eb5c74;
  border: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
  outline: none;
  margin: 10px 0 0 0;
}

.Login_error__2RZ1L {
  font-family: 'Open Sans', sans-serif;
  color: #cc0000;
}

.Login_logo__3Xk3i {
  width: 110px;
  height: 107.3px;
  object-fit: cover;
  margin: -50px 0 40px 0;
}

.BoxTalking_container__3aCN6 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  background-color: #e2e2e2;
}

.BoxTalking_default__1a5Vw {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.BoxTalking_default__1a5Vw i {
  color: #aaa;
  font-size: 10em;
}

.BoxTalking_default__1a5Vw h2 {
  color: #404040;
}

.BoxTalking_default__1a5Vw strong {
  color: #404040;
}

.BoxTalking_message__3CJnS {
  display: block;
}

.BoxTalking_topSpinner__2GFAt {
  margin: 10px auto;
}

@media only screen and (max-width: 600px) {
  .BoxTalking_default__1a5Vw {
    text-align: center;
  }
}

.ChatBox_container__2G42y {
  display: flex;
  flex: 1 1;
  height: 100vh;
  flex-direction: column;
  background-color: #e2e2e2;
}

@media only screen and (max-width: 600px) {
  .ChatBox_container__2G42y {
    width: 100vw;
  }
}

.ChatBox_default__3Ogmp {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.ChatBox_default__3Ogmp i {
  color: #aaa;
  font-size: 10em;
}

.ChatBox_default__3Ogmp h2 {
  color: #404040;
}

.ChatBox_default__3Ogmp strong {
  color: #404040;
}

.ChatList_container__sQsoB {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: 0.2px solid #ddd;
  background-color: #eee;
  height: 100vh;
}

@media screen and (max-width: 800px) {
  .ChatList_container__sQsoB {
    width: 100vw;
  }
}

.ChatList_chatList__UNsyC {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ChatList_options__S8W_3 {
  width: 100%;
  text-align: center;
  padding: 5px;
}

.ChatList_options__S8W_3:hover {
  background-color: rgb(202, 202, 202);
  cursor: pointer;
}

.ChatList_button__1bzwR {
  background-color: rgb(255, 255, 255);
  color: gray;
}

.ChatList_busca__1hTR_,
.ChatList_busca__1hTR_:focus {
  border: none;
  border-radius: unset;
  width: 100%;
  height: 50px;
}

.ChatList_filter__3eov7 {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 30px;
  align-items: center;
  border: 1px solid #7070703a;
}

.ChatList_busca__1hTR_:active {
  border: none;
  border-style: none;
  border-radius: unset;
}

.ChatList_notFound__3Hgp0 {
  text-align: center;
  font-size: 18px;
  padding: 10px 20px;
}

.ChatList_notFound__3Hgp0 p {
  padding-left: 8px;
}

.Chat_container__2s9_1 {
  display: grid;
  grid-template-columns: 25vw 75vw;
  height: 100%;
  background-color: #e2e2e2;
}

@media only screen and (max-width: 600px) {
  .Chat_container__2s9_1 {
    grid-template-columns: 100%;
  }
}

.Chat_spinner__1ft9s {
  height: 100%;
  display: flex;
}

.Chat_default__1_WnC {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
}

.Chat_default__1_WnC i {
  color: #eb5c74;
  font-size: 10em;
}

.Chat_default__1_WnC h2 {
  color: #404040;
}

.Chat_default__1_WnC strong {
  color: #404040;
  line-height: 180%;
  margin: 10px;
}

.Chat_emptyUsersContainer__2AIb2 {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #e2e2e2;
}

.VideoFeedback_container__1vOpq {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 350px;
  width: 330px;
  text-align: center;
  margin-top: 20px;
}

.VideoFeedback_star__3YER_ {
  font-size: 32px;
  padding: 0 5px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.VideoFeedback_star__3YER_:active {
  font-size: 36px;
}

.VideoFeedback_input__3QX48 {
  width: 100%;
  margin: 25px 0;
  border: 1px solid #7070707a;
  border-radius: 5px;
  padding: 10px;
}

.VideoFeedback_success__uZIkF {
  font-size: 22px;
}

.Header_row__2xeU_ {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #eee;
  box-shadow: 0px 3px 30px -9px rgba(0, 0, 0, 0.75);
}

.Header_buttonDiv__2-XY4 {
  width: 100px;
  text-align: center;
  float: left;
  background-color: #0071bf;
  transition: 0.4s ease-in;
  cursor: pointer;
}

.Header_buttonDiv__2-XY4:hover {
  background-color: #3cb2df;
}

.Header_goBack__vBLAH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 70px;
  font-size: 20px;
  color: white;
  transition: 0.2s ease-in;
  text-align: center;
  cursor: pointer;
}

.Header_rowTitle__1yZeu {
  float: left;
  width: auto;
  height: 100%;
  padding-left: 30px;
}

.Header_header__lnwZB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.Header_profilePic__3-mXz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Header_img__3N3Jv {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  border-radius: 50%;
  border: 1px solid blanchedalmond;
}

.Header_profileMetadata__3UGis {
  display: flex;
  flex-direction: column;
}

.Header_menu__1Z8qe {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.Header_icon__2kIhn {
  background-color: rgb(236, 236, 236);
  border: 1px solid rgb(211, 211, 211);
  color: #0071bf;
  padding: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s ease-in;
  margin-right: 25px;
  margin-left: 25px;
}

.Header_icon__2kIhn:active {
  background-color: rgb(226, 226, 226);
}

.Header_icon__2kIhn:hover {
  background-color: rgb(226, 226, 226);
  color: #3cb2df;
}

.Header_content__1EUis {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
}

.Header_item1__1-SwB {
  width: 40%;
  height: 325px;
  background-color: #bec8cf;
  border-radius: 10px;
}

.Header_item2__3s8z3 {
  width: 40%;
  background-color: #8d959b;
  border-radius: 10px;
}

.CreateAvailableDay_container__xMnHc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 30px;
  min-width: 330px;
  max-width: 330px;
  min-height: 350px;
}

.CreateAvailableDay_error__9OIRv {
  color: red;
  text-align: center;
  font-weight: bold;
}

select {
  border-radius: 5px;
  padding: 7px;
  border: 1px solid #707070;
  width: 100%;
}

input {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #707070;
}

.CreateAvailableDay_container__xMnHc p {
  margin: 20px 0;
}

@media (max-width: 600px) {
  .CreateAvailableDay_container__xMnHc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    padding: 30px;
    min-height: 350px;
  }
}

.AvailableDays_container__3Ypvv {
  display: flex;
  flex-direction: column;
  border: 1px solid #7070703a;
  border-radius: 15px;
}

.AvailableDays_header__2IkyP {
  width: 550px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 25px;
  align-items: center;
  border-bottom: 1px solid #7070703a;
}

.AvailableDays_headerTitle__1Z_s6 p {
  margin: 10px;
  font-size: 22px;
}

.AvailableDays_headerButton__2N191 {
  display: flex;
  align-items: center;
  margin: 15px;
}

.AvailableDays_list__3KP3J {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #7070702a;
  padding: 5px 25px;
}

.AvailableDays_optionsList__6yqp0 {
  display: flex;
  min-width: 120px;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  align-self: center;
}

@media (max-width: 600px) {
  .AvailableDays_header__2IkyP {
    width: 300px;
    flex-direction: column;
    justify-items: center;
    text-align: center;
  }
  .AvailableDays_list__3KP3J {
    flex-direction: column;
  }

  .AvailableDays_optionsList__6yqp0 {
    display: flex;
    flex-direction: row;
    width: 50%;
  }
}

.Profile_cards__3g9-_ {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 30px;
}

.Historic_container__2p6CO {
  display: flex;
  flex-direction: column;
}

.Historic_content__WM_G1 {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.Balance_balanceCard__EYO99 {
  margin: 30px auto;
  border-radius: 12px;
  box-shadow: 8px 8px 30px -9px rgba(0, 0, 0, 0.75);
  padding-top: 10px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.Balance_info__28zmB {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.Balance_withdraw__yAImI {
  text-align: center;
  font-size: 14px;
}

.Balance_halfText__2t65E {
  text-align: center;
}

.Balance_money__1BgES {
  padding-left: 10px;
  color: rgb(10, 206, 10);
}

.Balance_transfer__3yNsa {
  background-color: #86d2ea;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 10px;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.1s ease-in;
  font-weight: 600;
}

.Balance_transfer__3yNsa:hover {
  background-color: #32c2ee;
}

.Balance_error__3mN9P {
  text-align: center;
  color: red;
}

.Balance_success__3e7eu {
  text-align: center;
  color: green;
}

@media (max-width: 600px) {
  .Balance_balanceCard__EYO99 {
    width: 100%;
  }

  .Balance_halfText__2t65E {
    text-align: center;
    font-size: 14px;
  }

  .Balance_money__1BgES {
    text-align: center;
    font-size: 14px;
  }
}

.Schedule_appointment__1WRTI {
  /* display: grid; */
  /* grid-template-columns: 80fr 20fr; */
  /* padding: 1em; */
  background-color: white;
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: 10px 20px 10px 0;
  transition: 1s ease-in;
}

.Schedule_appointment__1WRTI p {
  margin: 0;
  margin-left: 15px;
}

.Schedule_header__1dWZm {
  background-color: #3cb2df;
  padding: 1em;
}

.Schedule_header__1dWZm h3 {
  margin: 0;
}

.Schedule_content__3Ht6j {
  display: flex;
  padding: 15px;
}

.Schedule_right__1AOLP {
  display: flex;
  flex-direction: column;
}

.Schedule_left__m-frv {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Schedule_rightIcon__3wqty {
  margin: auto;
}

.Schedule_title__2tzZ2 {
  font-weight: bolder;
}

.Schedule_subtitle__1Yhmv {
  font-size: smaller;
}

.Schedule_activeSubtitle__3gFea {
  font-size: smaller;
  color: #00d963;
  font-weight: bolder;
  padding-top: 25px;
}

.Schedule_roundButton__1js-n {
  border-radius: 50%;
  padding: 10px;
  border: none;
  background-color: #00d963;
}

.Schedule_contentContainer__R7e7A {
  height: 100%;
}

.Schedule_spinner__2-t1M {
  height: 100%;
  display: flex;
}

.Schedule_rightIcon__3Jw6T {
  margin: auto;
}

.Schedule_title__3jeLX {
  font-weight: bolder;
}

.Schedule_subtitle__2HCQa {
  font-size: smaller;
}

.Schedule_activeSubtitle__yRXo9 {
  font-size: smaller;
  color: #00d963;
  font-weight: bolder;
  padding-top: 25px;
}

.Schedule_roundButton__4psfA {
  border-radius: 50%;
  padding: 10px;
  border: none;
  background-color: #00d963;
}

.Schedule_roundButton__4psfA i {
  color: white;
}

.Schedule_spinner__32_ZG {
  height: 100%;
  display: flex;
}

html,
body,
#root {
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Montserrat', 'Open Sans', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #cde6fa 14%, #edf7ff 64%);
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover {
  background: radial-gradient(circle, #b5d7f2 14%, #b1d6f5 64%);
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 7px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}

