.container {
  display: grid;
  grid-template-columns: 5fr 5fr;
  /* grid-template-rows: 165px auto 250px; */
  grid-template-areas:
    'header header'
    'tabs notes'
    'appos notes';
  /* 'goals notes'; */
  /* height: 100%; */
}

@media (max-width: 600px) {
  .container {
    display: unset;
    min-height: unset;
    grid-template-columns: unset;
    grid-template-rows: unset;
    grid-template-areas: unset;
    grid-gap: unset;
  }
}
