.reschedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.calendar {
  display: flex;
  justify-content: 'column';
  align-self: 'center';
  margin: 20px auto;
  background-color: blue;
  border-radius: 10px;
}

.agenda {
  max-width: 640px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.hour {
  background-color: #2473a8;
  color: white;
  padding: 3px 10px;
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.hour:hover {
  background-color: #5ab9f8;
}

.error {
  color: red;
  font-weight: bold;
  margin-bottom: 15px;
}
