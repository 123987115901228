.container {
  display: grid;
  grid-template-columns: 25vw 75vw;
  height: 100%;
  background-color: #e2e2e2;
}

@media only screen and (max-width: 600px) {
  .container {
    grid-template-columns: 100%;
  }
}

.spinner {
  height: 100%;
  display: flex;
}

.default {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
}

.default i {
  color: #eb5c74;
  font-size: 10em;
}

.default h2 {
  color: #404040;
}

.default strong {
  color: #404040;
  line-height: 180%;
  margin: 10px;
}

.emptyUsersContainer {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #e2e2e2;
}
