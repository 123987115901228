.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: 0.2px solid #ddd;
  background-color: #eee;
  height: 100vh;
}

@media screen and (max-width: 800px) {
  .container {
    width: 100vw;
  }
}

.chatList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.options {
  width: 100%;
  text-align: center;
  padding: 5px;
}

.options:hover {
  background-color: rgb(202, 202, 202);
  cursor: pointer;
}

.button {
  background-color: rgb(255, 255, 255);
  color: gray;
}

.busca,
.busca:focus {
  border: none;
  border-radius: unset;
  width: 100%;
  height: 50px;
}

.filter {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 30px;
  align-items: center;
  border: 1px solid #7070703a;
}

.busca:active {
  border: none;
  border-style: none;
  border-radius: unset;
}

.notFound {
  text-align: center;
  font-size: 18px;
  padding: 10px 20px;
}

.notFound p {
  padding-left: 8px;
}
