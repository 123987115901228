.balanceCard {
  margin: 30px auto;
  border-radius: 12px;
  -webkit-box-shadow: 8px 8px 30px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 8px 8px 30px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 8px 8px 30px -9px rgba(0, 0, 0, 0.75);
  padding-top: 10px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.withdraw {
  text-align: center;
  font-size: 14px;
}

.halfText {
  text-align: center;
}

.money {
  padding-left: 10px;
  color: rgb(10, 206, 10);
}

.transfer {
  background-color: #86d2ea;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 10px;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.1s ease-in;
  font-weight: 600;
}

.transfer:hover {
  background-color: #32c2ee;
}

.error {
  text-align: center;
  color: red;
}

.success {
  text-align: center;
  color: green;
}

@media (max-width: 600px) {
  .balanceCard {
    width: 100%;
  }

  .halfText {
    text-align: center;
    font-size: 14px;
  }

  .money {
    text-align: center;
    font-size: 14px;
  }
}
