.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.avatar {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.profile {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-transform: capitalize;
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;
}

.profile strong {
  font-size: 1.5em;
  font-family: 'Open Sans', sans-serif;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
}

.iconDiv {
  width: 25px;
  text-align: center;
}

.icon {
  margin-right: 10px;
  color: #404040;
  align-self: center;
}

.bold {
  font-weight: bold;
  text-align: center;
}

@media (max-width: 600px) {
  .container {
    display: unset;
  }

  .avatar {
    padding: 10px;
  }

  .profile {
    padding: 0px 20px;
  }

  .profile strong {
    font-size: 20px;
  }
}
