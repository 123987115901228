.container {
  display: flex;
  width: 100%;
  height: 94vh;
  background-color: #333;
  position: relative;
}

.remoteMedia {
  width: 100%;
  position: relative;
  align-items: center;
  text-align: center;
  z-index: 0;
}

.video {
  height: 100vh;
  margin-left: 50%;
  transform: translateX(-50%);
}

.localVideo {
  width: 100%;
}

.localMedia {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 130px;
  /* height: 200px; */
  z-index: 1;
  background-color: #333;
}

.disconnect {
  background-color: red;
  text-align: center;
  padding: 0 15px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  transition: 0.3s ease-in;
}

.disconnect:hover {
  -moz-transform: rotate(25deg);
  -webkit-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
}

.errorDiv {
  background-color: rgb(192, 3, 3);
  color: white;
  width: max-content;
  padding: 15px;
  border-radius: 15px;
  z-index: 1;
}

.connection {
  font-size: 55px;
  text-align: center;
  margin: 0.5em;
  z-index: 3;
  display: flex;
  width: 250px;
  justify-content: space-between;
}

.errorDiv {
  position: absolute;
  left: 0;
  right: 0;
  top: 20;
  margin: 20px auto;
  text-align: center;
  background-color: rgb(192, 3, 3);
  color: white;
  width: max-content;
  padding: 15px;
  border-radius: 15px;
  z-index: 1;
}

.buttons {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: center;
  align-items: center;
}

.toastDiv {
  background-color: rgba(85, 85, 85, 0.842);
  color: white;
  border-radius: 30px;
  width: max-content;
  padding: 15px;
  text-align: center;
}

.icon {
  z-index: 2;
  border-radius: 100%;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.icon:hover {
  color: white;
}

.icon:visited {
  background-color: blue;
}

@media (min-width: 600px) {
  .container {
    margin: auto;
  }

  .video {
    height: 100vh;
    margin-left: unset;
    transform: unset;
  }

  .localMedia {
    bottom: 10px;
    right: 20px;
    width: 300px;
    height: auto;
    z-index: 1;
  }
}
